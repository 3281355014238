import { MigrationTool } from '../types';

export const migrationTools: MigrationTool[] = [
  {
    id: 'proofpoint',
    name: 'ProofPoint',
    category: 'email',
    description: 'Enterprise email security and filtering solution',
    icon: '📧'
  },
  {
    id: 'mimecast',
    name: 'Mimecast',
    category: 'email',
    description: 'Cloud-based email management and security',
    icon: '🔒'
  },
  {
    id: 'okta',
    name: 'Okta',
    category: 'security',
    description: 'Identity and access management platform',
    icon: '🔑'
  },
  {
    id: 'lastpass',
    name: 'LastPass',
    category: 'security',
    description: 'Password management and authentication',
    icon: '🗝️'
  },
  {
    id: 'keepass',
    name: 'KeePass',
    category: 'security',
    description: 'Open-source password manager',
    icon: '🔐'
  },
  {
    id: 'box',
    name: 'Box',
    category: 'storage',
    description: 'Cloud content management and file sharing',
    icon: '📦'
  },
  {
    id: 'dropbox',
    name: 'Dropbox',
    category: 'storage',
    description: 'File hosting and synchronization',
    icon: '💾'
  },
  {
    id: 'sharepoint',
    name: 'SharePoint',
    category: 'storage',
    description: 'Collaborative platform and document management',
    icon: '📁'
  }
];