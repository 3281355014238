import React, { useState, useEffect } from 'react';
import { X, ArrowRight, Clock, AlertTriangle, BookOpen } from 'lucide-react';
import { MigrationTool, MigrationStep } from '../types';
import { migrationTools } from '../data/migrationTools';

interface MigrationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function MigrationModal({ isOpen, onClose }: MigrationModalProps) {
  const [fromTool, setFromTool] = useState<MigrationTool | null>(null);
  const [toTool, setToTool] = useState<MigrationTool | null>(null);
  const [steps, setSteps] = useState<MigrationStep[]>([]);
  const [currentStep, setCurrentStep] = useState<'selection' | 'plan'>('selection');

  useEffect(() => {
    if (fromTool && toTool) {
      // Generate migration steps based on selected tools
      const generatedSteps: MigrationStep[] = [
        {
          id: '1',
          title: 'Initial Assessment',
          description: `Evaluate current ${fromTool.name} setup and prepare ${toTool.name} environment`,
          estimatedTime: '2-3 days',
          complexity: 'medium',
          prerequisites: ['Admin access to both systems', 'Current system documentation']
        },
        {
          id: '2',
          title: 'Data Export',
          description: `Export all necessary data from ${fromTool.name}`,
          estimatedTime: '1-2 days',
          complexity: 'low',
          resources: [
            {
              title: `${fromTool.name} Export Guide`,
              url: '#'
            }
          ]
        },
        {
          id: '3',
          title: 'Data Mapping',
          description: 'Map data structures between systems',
          estimatedTime: '2-3 days',
          complexity: 'high',
          prerequisites: ['Completed data export', 'Understanding of both system schemas']
        },
        {
          id: '4',
          title: `${toTool.name} Configuration`,
          description: 'Set up and configure the target system',
          estimatedTime: '3-4 days',
          complexity: 'high',
          resources: [
            {
              title: `${toTool.name} Setup Documentation`,
              url: '#'
            }
          ]
        },
        {
          id: '5',
          title: 'Migration Execution',
          description: 'Perform the actual data migration',
          estimatedTime: '1-2 weeks',
          complexity: 'high',
          prerequisites: ['Completed configuration', 'Migration plan approval']
        }
      ];
      setSteps(generatedSteps);
    }
  }, [fromTool, toTool]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-900">System Migration</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6 overflow-y-auto max-h-[calc(90vh-120px)]">
          {currentStep === 'selection' ? (
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Migrate from:
                </label>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {migrationTools.map((tool) => (
                    <div
                      key={tool.id}
                      className={`p-4 border rounded-lg cursor-pointer transition-all ${
                        fromTool?.id === tool.id
                          ? 'border-blue-500 bg-blue-50'
                          : 'border-gray-200 hover:border-blue-300'
                      }`}
                      onClick={() => setFromTool(tool)}
                    >
                      <div className="flex items-center space-x-3">
                        <span className="text-2xl">{tool.icon}</span>
                        <div>
                          <h3 className="font-medium text-gray-900">{tool.name}</h3>
                          <p className="text-sm text-gray-500">{tool.category}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {fromTool && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Migrate to:
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {migrationTools
                      .filter(
                        (tool) =>
                          tool.category === fromTool.category && tool.id !== fromTool.id
                      )
                      .map((tool) => (
                        <div
                          key={tool.id}
                          className={`p-4 border rounded-lg cursor-pointer transition-all ${
                            toTool?.id === tool.id
                              ? 'border-blue-500 bg-blue-50'
                              : 'border-gray-200 hover:border-blue-300'
                          }`}
                          onClick={() => setToTool(tool)}
                        >
                          <div className="flex items-center space-x-3">
                            <span className="text-2xl">{tool.icon}</span>
                            <div>
                              <h3 className="font-medium text-gray-900">{tool.name}</h3>
                              <p className="text-sm text-gray-500">{tool.category}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="space-y-6">
              <div className="flex items-center space-x-4 text-lg font-medium text-gray-900">
                <span>{fromTool?.name}</span>
                <ArrowRight className="h-5 w-5 text-gray-500" />
                <span>{toTool?.name}</span>
              </div>

              <div className="space-y-6">
                {steps.map((step) => (
                  <div
                    key={step.id}
                    className="border border-gray-200 rounded-lg p-6 space-y-4"
                  >
                    <div className="flex justify-between items-start">
                      <h3 className="text-lg font-medium text-gray-900">{step.title}</h3>
                      <div className="flex items-center space-x-2 text-sm">
                        <Clock className="h-4 w-4 text-gray-500" />
                        <span className="text-gray-500">{step.estimatedTime}</span>
                      </div>
                    </div>

                    <p className="text-gray-600">{step.description}</p>

                    {step.prerequisites && (
                      <div className="space-y-2">
                        <div className="flex items-center space-x-2 text-sm font-medium text-amber-600">
                          <AlertTriangle className="h-4 w-4" />
                          <span>Prerequisites</span>
                        </div>
                        <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                          {step.prerequisites.map((prereq, index) => (
                            <li key={index}>{prereq}</li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {step.resources && (
                      <div className="space-y-2">
                        <div className="flex items-center space-x-2 text-sm font-medium text-blue-600">
                          <BookOpen className="h-4 w-4" />
                          <span>Resources</span>
                        </div>
                        <ul className="list-disc list-inside text-sm space-y-1">
                          {step.resources.map((resource, index) => (
                            <li key={index}>
                              <a
                                href={resource.url}
                                className="text-blue-600 hover:text-blue-700"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {resource.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="border-t p-6 bg-gray-50 flex justify-between">
          {currentStep === 'plan' && (
            <button
              onClick={() => setCurrentStep('selection')}
              className="btn-secondary"
            >
              Back to Selection
            </button>
          )}
          <div className="ml-auto">
            <button
              onClick={onClose}
              className="btn-secondary mr-3"
            >
              Cancel
            </button>
            {currentStep === 'selection' ? (
              <button
                onClick={() => setCurrentStep('plan')}
                className="btn"
                disabled={!fromTool || !toTool}
              >
                Generate Plan
              </button>
            ) : (
              <button
                onClick={() => {
                  // Here you would typically save the migration plan
                  onClose();
                }}
                className="btn"
              >
                Save Plan
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}