import React, { useState } from 'react';
import Navbar from './components/Navbar';
import ScenarioCard from './components/ScenarioCard';
import ProjectProgress from './components/ProjectProgress';
import MigrationModal from './components/MigrationModal';
import { ArrowRight, BarChart2, GitMerge, Palette } from 'lucide-react';
import type { Project, ProjectScenario } from './types';

const scenarios: ProjectScenario[] = [
  {
    id: '1',
    title: 'System Migration',
    description: 'Seamlessly migrate between different systems and platforms',
    icon: '🔄'
  },
  {
    id: '2',
    title: 'Tech Consolidation',
    description: 'Consolidate and optimize your technical infrastructure',
    icon: '🔗'
  },
  {
    id: '3',
    title: 'Brand Transition',
    description: 'Guide through complete brand and identity changes',
    icon: '🎨'
  }
];

const recentProjects: Project[] = [
  {
    id: '1',
    name: 'Google to Microsoft Migration',
    type: 'migration',
    status: 'in-progress',
    progress: 65,
    startDate: new Date('2024-02-15')
  },
  {
    id: '2',
    name: 'Tech Stack Consolidation',
    type: 'consolidation',
    status: 'planning',
    progress: 25,
    startDate: new Date('2024-03-01')
  }
];

function App() {
  const [selectedScenario, setSelectedScenario] = useState<ProjectScenario | null>(null);
  const [showMigrationModal, setShowMigrationModal] = useState(false);

  const handleScenarioSelect = (scenario: ProjectScenario) => {
    setSelectedScenario(scenario);
    if (scenario.id === '1') { // System Migration
      setShowMigrationModal(true);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Welcome to Project Path</h1>
          <p className="mt-2 text-gray-600">Select a scenario to begin your project journey</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {scenarios.map((scenario) => (
            <ScenarioCard
              key={scenario.id}
              scenario={scenario}
              onSelect={handleScenarioSelect}
            />
          ))}
        </div>

        <div className="mb-8">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-900">Recent Projects</h2>
            <button className="text-blue-600 hover:text-blue-700 text-sm font-medium flex items-center">
              View All
              <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {recentProjects.map((project) => (
              <ProjectProgress key={project.id} project={project} />
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-lg p-6 text-white">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Quick Stats</h3>
              <BarChart2 className="h-6 w-6 opacity-75" />
            </div>
            <p className="text-3xl font-bold">85%</p>
            <p className="text-blue-100 mt-1">Average completion rate</p>
          </div>

          <div className="bg-gradient-to-br from-purple-500 to-purple-600 rounded-lg p-6 text-white">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Active Projects</h3>
              <GitMerge className="h-6 w-6 opacity-75" />
            </div>
            <p className="text-3xl font-bold">12</p>
            <p className="text-purple-100 mt-1">Currently in progress</p>
          </div>

          <div className="bg-gradient-to-br from-green-500 to-green-600 rounded-lg p-6 text-white">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Completed</h3>
              <Palette className="h-6 w-6 opacity-75" />
            </div>
            <p className="text-3xl font-bold">45</p>
            <p className="text-green-100 mt-1">Successfully delivered</p>
          </div>
        </div>
      </main>

      <MigrationModal
        isOpen={showMigrationModal}
        onClose={() => setShowMigrationModal(false)}
      />
    </div>
  );
}

export default App;