import React from 'react';
import type { ProjectScenario } from '../types';
import { ArrowRight } from 'lucide-react';

interface ScenarioCardProps {
  scenario: ProjectScenario;
  onSelect: (scenario: ProjectScenario) => void;
}

export default function ScenarioCard({ scenario, onSelect }: ScenarioCardProps) {
  return (
    <div 
      className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 hover:shadow-md transition-shadow cursor-pointer"
      onClick={() => onSelect(scenario)}
    >
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-gray-900">{scenario.title}</h3>
          <p className="mt-2 text-sm text-gray-600">{scenario.description}</p>
        </div>
        <div className="ml-4">
          <div className="p-2 bg-blue-50 rounded-lg">
            <span className="text-2xl">{scenario.icon}</span>
          </div>
        </div>
      </div>
      
      <div className="mt-4 flex items-center text-blue-600 text-sm font-medium">
        Start Project
        <ArrowRight className="ml-2 h-4 w-4" />
      </div>
    </div>
  );
}